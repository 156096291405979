module.exports = {
  adsetPublicUrl: 'https://adset.alon.apester.dev',
  analyticsBaseUrl: 'https://analytics.alon.apester.dev',
  authBaseUrl: 'https://auth.alon.apester.dev',
  campaignBaseUrl: 'https://campaign.alon.apester.dev',
  campaignApiPublicUrl: 'https://campaign-api.alon.apester.dev',
  adsetApiPublicUrl: 'https://adset-api.alon.apester.dev',
  consoleBaseUrl: 'https://console.alon.apester.dev',
  vreminiUrl: 'https://vremini.alon.apester.dev',
  editorBaseUrl: 'https://app.alon.apester.dev',
  eventsPublicUrl: 'https://events.alon.apester.dev',
  imagesNewUrl: 'https://img.alon.apester.dev',
  imagesUrl: 'https://img.alon.apester.dev',
  interactionBaseUrl: 'https://interaction.alon.apester.dev',
  jobsUrl: 'https://jobs-api.alon.apester.dev',
  paymentsUrl: 'https://payments.alon.apester.dev/',
  planApiUrl: 'https://plans-api.alon.apester.dev',
  playerBaseUrl: 'https://renderer.alon.apester.dev/v2/',
  portalUrl: 'https://portal.alon.apester.dev',
  staticBucket: 'https://static.alon.apester.dev/',
  stripePK: 'pk_test_ws6qGQGNuKq9GtmPTbtiUQ4g00k49LBFFY',
  usersBaseUrl: 'https://users.alon.apester.dev',
  wixIntegrationUrl: 'https://wix-integration.alon.apester.dev',
  webhooksBaseUrl: 'https://webhooks.alon.apester.dev',
  release: '2.5.37',
};
